import React, { useEffect, useState } from 'react';
import styles from './AppointmentToReminderItem.module.css';
import { SendWhatsAppMessage } from "../../../../api/WhatsappAPI";
import AppointmensState from "../../../../consts/AppointmentsState";
import CustomButtom from '../../../../components/buttons/CustomButton';
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import MessagesTemplate from '../../../../consts/MessagesTemplate';
import CalendarConfig from '../../../../consts/CalendarConfig';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { UpdateAppointmentStatusAPI } from '../../../../api/API';
import ColorPalette from '../../../../colorpalette/ColorPalette';
import { Check } from '@mui/icons-material';

function AppointmentToReminderItem(props) {
    const { id, patientId, name,  date, time, phone, procedure, resource} = props;
    const todayDate = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    const messageStatusKey = `message7DaysReminderStatus-${date + time + id}`; // Unique key for each appointment's messages

    const [sentMessage, setSentMessage] = useState(false);

    const [state, setState] = useState(AppointmensState.Scheduled);

    useEffect(() => {
        setSentMessage(getSentMessage());
    },[]);

    useEffect(() => {
        if(sentMessage == true)
        {
            localStorage.setItem(messageStatusKey, true);
        }
        
    }, [sentMessage]);

    // Retrieve sent messages from localStorage
    function getSentMessage() {
        const storedData = localStorage.getItem(messageStatusKey);
        return storedData != null ? true : false;
    }

    // Handle phone click
    function OnPhoneClickHandler() {
        window.location.href = `tel:${phone}`;
    }
   
    // Handle the confirmation message click
    function OnAskForAppointmentConfirmClickHandler() {
        let template = MessagesTemplate.Appointment7DaysReminder;

        let message = template;
        let yyyymmdd = CalendarConfig.GetAAAAMMDDArray(date);
        let monthDay = yyyymmdd[2];
        let monthName = CalendarConfig.Months[yyyymmdd[1] - 1];

        const today = new Date();
        const appointmentDate = new Date(date);
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        let greetings = "";
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            greetings = "Bom dia";
        } else if (currentHour < 18) {
            greetings = "Boa tarde";
        } else {
            greetings = "Boa noite";
        }

        let when = "";
        if (appointmentDate.toDateString() === today.toDateString()) {
            when = "hoje";
        } else if (appointmentDate.toDateString() === tomorrow.toDateString()) {
            when = "amanhã";
        }
        else 
        {
           
        }

        message = message.replaceAll("{greetings}", greetings);
        message = message.replaceAll("{name}", name.split(' ')[0]);
        message = message.replaceAll("{when}", when);
        message = message.replaceAll("{day}", monthDay);
        message = message.replaceAll("{month}", monthName);
        message = message.replaceAll("{time}", CalendarConfig.GetTime(date));

      SendWhatsAppMessage(phone, MessagesTemplate.ConvertToWhatsAppText(message));
        setSentMessage(true);
    }

    // Handle status change
    const OnItemSelectHandler = (event) => {
        setState(event.target.value);
        UpdateAppointmentStatusAPI(id, event.target.value, (data) => { });
    };

    // Handle name click to open patient's page
    const onNameClickHandler = () => {
        window.open(`/patients/${patientId}`);
    }

    // Get appointment state style
    const GetStyle = () => {
        let statusStyle = AppointmensState.GetDataFromId(state);
        return {
            backgroundColor: statusStyle.backgroundColor,
            color: statusStyle.textColor,
            fontSize: "20px"
        }
    }

     // Handle cancellation message
     function SendCancellationMessageClickHandler() {
        let message = MessagesTemplate.AppointmentCancellationDueLackOfReturn;
        message = message.replaceAll("{name}", name.split(' ')[0]);
        SendWhatsAppMessage(phone, MessagesTemplate.ConvertToWhatsAppText(message));
    }

    // Render action buttons and message selection
    const GetActionState = () => {
        if (state === AppointmensState.Canceled) {
            return (
                <div style={{ color: ColorPalette.red }}>
                    <CustomButtom style={"secondary"} variant="outlined" icon={<IconWhatsapp color='error' />} label={""} onClick={SendCancellationMessageClickHandler} />
                </div>
            );
        }
        else if (state === AppointmensState.Confirmed) {
            return (
                null
            )
        }
        else {
            return (
                    <CustomButtom style={"secondary"} variant="outlined" icon={<IconWhatsapp color='primary' />} onClick={OnAskForAppointmentConfirmClickHandler} />
            );
        }
    }

    const getStatusSelector = () => {

        return (
            <FormControl fullWidth>
                <Select
                    value={state}
                    onChange={OnItemSelectHandler}
                    style={{ ...GetStyle() }}
                >
                    {AppointmensState.ChangeableBySystem.map((option, index) => {
                        let customStyle = AppointmensState.GetDataFromId(option);
                        return (
                            <MenuItem key={index} value={option} style={{ backgroundColor: customStyle.backgroundColor, height: "60px" }}>
                                {customStyle.icon}  {/*customStyle.label*/}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        )
    }

    return (
        <tr key={date + time} >
            <td style={{alignContent:"center"}}><b>{time}</b></td>
            <td onClick={onNameClickHandler} style={{alignContent:"center"}}> {name.split(' ')[0]}</td>
            <td style={{alignContent:"center"}}>{phone}</td>
            <td style={{alignContent:"center"}}>{sentMessage ?  <Check color='primary' /> : null}</td>
            <td style={{alignContent:"center"}}> {GetActionState()}</td>
            <td style={{alignContent:"center"}}> {getStatusSelector()}</td>
        </tr>
    );
}

export default AppointmentToReminderItem;
