import React from 'react';

import Login from '../pages/login/Login';
import Leads from '../pages/leads/Leads';
import WhatsappMessages from '../pages/whatsappMessages/WhatsappMessages';
import Dashboard from '../pages/dashboard/Dashboard';
import Appointments from '../pages/appointments/Appointments';
import Appointment from '../pages/appointment/Appointment';
import WaitingList from '../pages/waitingList/WaitingList';
import Invoice from '../pages/invoice/Invoice';
import Document from '../pages/document/Document';
import Patients from '../pages/patients/Patients';
import Patient from '../pages/patient/Patient';
import Reports from '../pages/reports/Reports';
import Statistics from '../pages/statistics/Statistics';
import Company from '../pages/company/Company'
import Logout from '../pages/logout/Logout';

import IconDashboard from '@mui/icons-material/SpaceDashboardOutlined';
import IconLeads from '@mui/icons-material/GroupsOutlined';
import IconCalendar from '@mui/icons-material/CalendarMonthOutlined';
import IconPatients from '@mui/icons-material/PeopleAltOutlined';
import IconStatistics from '@mui/icons-material/BarChartOutlined';
import IconReports from '@mui/icons-material/DocumentScannerOutlined';
import IconCompany from '@mui/icons-material/ApartmentOutlined';
import IconResources from '@mui/icons-material/Person2Outlined';
import IconServices from '@mui/icons-material/DesignServicesOutlined';
import IconSettings from '@mui/icons-material/SettingsOutlined';
import IconLogout from '@mui/icons-material/LogoutOutlined';
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import IconWaitingList from '@mui/icons-material/HourglassEmptyOutlined';

export const IdLogin = "login";
export const IdDashboard = "dashboard";
export const IdLeads = "leads";
export const IdWhatsappMessages = "whatsapp";
export const IdSchedule = "schedule";
export const IdSchedule_Appointments = "schedule/appointments";
export const IdAppointment = "schedule/appointments/appointment";
export const IdWaitingList = "schedule/waitingList";
export const IdInvoice = "invoice";
export const IdDocument = "document";
export const IdPatients = "patients";
export const IdPatients_Patient = "patients/patient";
export const IdStatistics = "statistics";
export const IdReports = "reports";
export const IdCompany = "company";
export const IdResources = "resources";
export const IdServices = "services";
export const IdSettings = "settings";
export const IdLogout = "logout";

const AppConfig = {
  MinScreenSizeX: 1010,
  Routes:
    [
      {
        id: IdLogin,
        path: "/login",
        label: "Login",
        element: <Login />,
      },
      {
        id: IdDashboard,
        path: "/dashboard",
        label: "Painel geral",
        element: <Dashboard />,
        icon: <IconDashboard fontSize='small'/>,
      },
      {
        id: IdLeads,
        path: "/leads",
        label: "Leads",
        element: <Leads />,
        icon: <IconLeads fontSize='small'/>,
      },
      {
        id: IdWhatsappMessages,
        path: "/whatsapp",
        label: "Whatsapp",
        element: <WhatsappMessages />,
        icon: <IconWhatsapp fontSize='small'/>,
      },
      {
        id: IdSchedule_Appointments,
        path: "/schedule/appointments",
        label: "Agenda / Agendamentos",
        element: <Appointments />,
        icon: <IconCalendar fontSize='small'/>,
      },
      {
        id: IdWaitingList,
        path: "/schedule/waitingList",
        label: "Agenda / Lista de espera",
        element: <WaitingList />,
        icon: <IconWaitingList fontSize='small'/>,
      },
      {
        id: IdAppointment,
        path: "/appointment/:id",
        label: "Consulta",
        element: <Appointment fontSize='small'/>,
      },
      {
        id: IdInvoice,
        path: "/invoice/:id",
        label: "Recibo",
        element: <Invoice fontSize='small'/>,
      },
      {
        id: IdDocument,
        path: "/document/:id",
        label: "Documento",
        element: <Document fontSize='small'/>,
      },
      {
        id: IdPatients,
        path: "/patients",
        label: "Pacientes",
        element: <Patients />,
        icon: <IconPatients fontSize='small'/>,
      },
      {
        id: IdPatients_Patient,
        path: "/patients/:id",
        label: "Paciente",
        element: <Patient />,
      },
      {
        id: IdStatistics,
        path: "/statistics",
        label: "Estatísticas",
        element: <Statistics />,
        icon: <IconStatistics fontSize='small'/>,
      },
      {
        id: IdReports,
        path: "/reports",
        label: "Relatórios",
        element: <Reports />,
        icon: <IconReports fontSize='small'/>,
      },
      {
        id: IdCompany,
        path: "/company",
        label: "Empresa",
        element: <Company />,
        icon: <IconCompany fontSize='small'/>,
      },
      {
        id: IdResources,
        path: "/resources",
        label: "Funcionários",
        icon: <IconResources fontSize='small'/>,
      },
      {
        id: IdServices,
        path: "/services",
        label: "Serviços",
        icon: <IconServices fontSize='small'/>,
      },
      {
        id: IdSettings,
        path: "/settings",
        label: "Configurações",
        icon: <IconSettings fontSize='small'/>,
      },
      {
        id: IdLogout,
        path: "/logout",
        label: "Sair",
        icon: <IconLogout />,
        element: <Logout fontSize='small'/>,
      }
    ],
  MenuRoutes: [
     { label: "", routes: [IdDashboard] },
     { label: "Leads", routes: [IdLeads, /*IdWhatsappMessages*/] },
     { label: "Agenda", routes: [IdSchedule_Appointments, IdWaitingList] },
     { label: "Pacientes", routes: [IdPatients] },
    { label: "Dados", routes: [IdStatistics, IdReports] },
    { label: "Negócio", routes: [IdCompany] }, // ,IdResources,IdServices
    { label: "Sistema", routes: [IdLogout] } //IdSettings
  ],
  GetRouteById: (id) => {
    return AppConfig.Routes.find((route) => {
      return route.id === id;
    })
  },
  GetRouteLabel: (path) => {
    return AppConfig.Routes.find((route) => {
      return route.path === path;
    })
  },
}

export default AppConfig;