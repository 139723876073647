import React, { useState, useEffect } from "react";
import PopupBase from "../base/PopupBase";
import CustomButton from "../../components/buttons/CustomButton";
import { TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { DataProviderInstance } from '../../api/DataProvider';
import LeadsConfig from '../../consts/LeadsConfig';

function CreateEditLeadPopup(props) {
  const { Data, OnHide, OnUpdate } = props;

  const { CompanyConfig, UpdateLead } = DataProviderInstance();

  const initialLeadData = {
    id: Data?.id || null,
    createdAt: Data?.createdAt || "",
    name: Data?.name || "",
    phone: Data?.phone || "",
    channel: Data?.channel || null,
    campaignId: Data?.campaignId || null,
    sourceId: Data ? CompanyConfig.LeadsCampaigns.find((camp) => camp.id == Data?.campaignId + "").sourceId : null,
    status: Data?.status || "new",
    result: Data?.result || "pending",
    observations: Data?.observations || "",
  };

  const [leadData, setLeadData] = useState(initialLeadData);

  const statusOptions = LeadsConfig.StatusOptions;
  const resultOptions = LeadsConfig.ResultOptions;
  const channelOptions = LeadsConfig.ChannelOptions;

  const sourceOptions = CompanyConfig.LeadsSources.map((item) => ({
    id: item.id,
    name: item.name,
  }));

  const campaignOptions = CompanyConfig.LeadsCampaigns.map((item) => ({
    id: item.id,
    name: item.name,
    sourceId: item.sourceId,
  }));

  
  const handleChange = (field, value) => {
    const updatedLeadData = { ...leadData, [field]: value };

  if (field === "campaign") {
    const selectedCampaign = campaignOptions.find((item) => item.name == value);
    updatedLeadData.campaignId = selectedCampaign?.id || "";
    updatedLeadData.sourceId = selectedCampaign?.sourceId;
  }

  if (field === "status") {
    if (value !== "finished") {
      updatedLeadData.result = "pending";
    }
  }

  setLeadData(updatedLeadData);
  };

  const updateData = () => {
    UpdateLead(leadData, (result) => {
      OnUpdate();
      OnHide();
    });
  }

  const saveClickHandler = () => {
    
    if(Data)
    {
      updateData();
    }
    else
    {
      OnHide();

    }
  };

  const getPositiveButton = () => (
    <CustomButton
      variant="contained"
      style="primary"
      label={Data ? "Salvar Alterações" : "Adicionar"}
      onClick={saveClickHandler}
    />
  );

  const getContent = () => (
    <div style={{ marginTop: "10px" }}>
      <div className="form">
        <div style={{ display: "flex", gap: "10px" }}>
          {/* Data */}
          {leadData.createdAt && (
            <div className="form-group">
              <TextField
                fullWidth
                disabled={true}
                label="Data de Criação"
                value={leadData.createdAt}
                variant="outlined"
                style={{ marginBottom: "15px" }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
          )}

          {/* Name */}
          <div className="form-group">
            <TextField
              fullWidth
              label="Nome"
              value={Data ? leadData.name : ""}
              onChange={(e) => handleChange("name", e.target.value)}
              variant="outlined"
              style={{ marginBottom: "15px" }}
            />
          </div>

          {/* Phone */}
          <div className="form-group">
            <TextField
              fullWidth
              disabled={!!Data}
              label="Telefone"
              value={leadData.phone}
              onChange={(e) => handleChange("phone", e.target.value)}
              variant="outlined"
              style={{ marginBottom: "15px" }}
              InputProps={{
                readOnly: !!Data, // Apenas leitura para edição
              }}
            />
          </div>
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          {/* Channel */}
          <div className="form-group">
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
              <InputLabel>Canal</InputLabel>
              <Select
                value={leadData.channel}
                onChange={(e) => handleChange("channel", e.target.value)}
              >
                {channelOptions.map((channel, index) => (
                  <MenuItem key={index} value={channel}>
                    {channel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* Source */}
          <div className="form-group">
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
              <InputLabel>Origem</InputLabel>
              <Select value={Data ? sourceOptions.find((item) => item.id == leadData.sourceId+"").name : ""} disabled>
                {sourceOptions.map((source, index) => (
                  <MenuItem key={index} value={source.name}>
                    {source.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* Campaign */}
          <div className="form-group">
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
              <InputLabel>Campanha</InputLabel>
              <Select
                value={Data ? campaignOptions.find((item) => item.id == leadData.campaignId)?.name : ""}
                onChange={(e) => handleChange("campaign", e.target.value)}
              >
                {campaignOptions.map((campaign, index) => (
                  <MenuItem key={index} value={campaign.name}>
                    {campaign.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          {/* Status */}
          <div className="form-group">
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={leadData.status}
                onChange={(e) => handleChange("status", e.target.value)}
              >
                {statusOptions.map((status, index) => (
                  <MenuItem key={index} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* Result */}
          <div className="form-group">
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
              <InputLabel>Resultado</InputLabel>
              <Select
                value={leadData.result}
                onChange={(e) => handleChange("result", e.target.value)}
              >
                {resultOptions.map((result, index) => (
                  <MenuItem key={index} value={result}>
                    {result}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>       

        {/* Observations */}
        <div className="form-group">
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Observações"
            value={leadData.observations}
            onChange={(e) => handleChange("observations", e.target.value)}
            variant="outlined"
            style={{ marginBottom: "15px" }}
          />
        </div>
      </div>
    </div>
  );

  const getTitle = () => (Data ? "Editar Lead" : "Criar Novo Lead");

  function onCloseClickHandler() {
    OnHide();
  }


  return (
    <PopupBase
      Title={getTitle()}
      Content={getContent()}
      Footer={getPositiveButton()}
      OnClose={onCloseClickHandler}
    />
  );
}

export default CreateEditLeadPopup;
