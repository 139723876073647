export const Channel_Whatsapp = "whatsapp";
export const Channel_Facebook = "facebook";
export const Channel_Instagram = "instagram";
export const Channel_Phone = "phone";
export const Channel_Email = "email";
export const Channel_Other = "other";

export const Status_New = "new"
export const Status_InProgress = "in_progress";
export const Status_Finished = "finished";

export const Result_Pending = "pending";
export const Result_Success = "success";
export const Result_Failure = "failure";

const LeadsConfig = {

    ChannelOptions: [
        Channel_Whatsapp,
        Channel_Facebook,
        Channel_Instagram,
        Channel_Phone,
        Channel_Email,
        Channel_Other
    ],
    
    StatusOptions: [
        Status_New,
        Status_InProgress,
        Status_Finished
    ],

    ResultOptions: [
        Result_Pending,
        Result_Success,
        Result_Failure
    ]
}

export default LeadsConfig;
