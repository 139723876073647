import React, { useEffect, useState, useRef } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import CustomButton from '../../components/buttons/CustomButton';
import styles from './Leads.module.css';
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import IconEdit from '@mui/icons-material/Edit';
import CreateEditLeadPopup from '../../popup/CreateEditLeadPopup/CreateEditLeadPopup';
import DateRangeHeader from '../../components/dateRangeHeader/DateRangeHeader';
import FilterList from '../../components/filters/FilterList';
import { ExportToExcel } from '../../utils/Utils';
import { SendWhatsAppMessage } from "../../api/WhatsappAPI";
import MessagesTemplate from "../../consts/MessagesTemplate";
import { MenuItem, Select } from '@mui/material';
import {CheckOutlined, CloseRounded} from '@mui/icons-material';
import ColorPalette from '../../colorpalette/ColorPalette';

function Leads() {

  const
    {
      CompanyConfig,
      GetLeads,
    } = DataProviderInstance()

  const VIEW_MODE_LIST = "Lista";
  const VIEW_MODE_COLUMN = "Coluna";

  const maxItemsPerPage = 25;
  const [loaded, setLoaded] = useState(false);
  const [leads, setLeads] = useState([]);
  const [filtered, setFiltered] = React.useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentLead, setCurrentLead] = useState(null);
  const [createOrEditPopupVisibility, setCreateOrEditPopupVisibility] = useState(false);

  const [currentFilters, setCurrentFilters] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [needsReload, setNeedsReload] = React.useState(false);

  const filters = ["Faturadas", "Faturadas e pediram nota fiscal"];
  const filterRef = useRef(null);

  const [viewMode, setViewMode] = React.useState(VIEW_MODE_COLUMN);

  useEffect(() => {
    setNeedsReload(true);
  }, []);

  useEffect(() => {
    if (needsReload) {
      setLoaded(false);
      LoadData();
    }
  }, [needsReload]);

  const LoadData = () => {
    GetLeads((result) => {
      setLeads(result.data);
      setLoaded(true);
      setNeedsReload(false);
    });
  }

  useEffect(() => {
    const parseDate = (dateString) => {
      if (!dateString) return null;

      // Divida a string no formato "YYYY-MM-DD"
      const [year, month, day] = dateString.split("-").map(Number);

      // Crie a data localmente
      return new Date(year, month - 1, day); // `month - 1` porque o mês começa em 0
    };

    const startDateParsed = parseDate(startDate);
    if (startDateParsed) {
      startDateParsed.setHours(0, 0, 0, 0);
    }

    const endDateParsed = parseDate(endDate);
    if (endDateParsed) {
      endDateParsed.setHours(23, 59, 59, 999);
    }

    const filtered = leads.filter((lead) => {
      const leadDate = new Date(lead.createdAt);

      return (
        (!startDateParsed || leadDate >= startDateParsed) &&
        (!endDateParsed || leadDate <= endDateParsed)
      );
    });

    setFiltered(filtered);
  }, [startDate, endDate, leads]);


  const filterChangeHandler = (filters) => {
    setCurrentFilters(filters);
  }

  const getColumn = () =>
  {
      const columns = {
        new: [],
        inProgress: [],
        finished: []
      };

      filtered.forEach((leadData, i) => {
        let { createdAt, name, phone, channel, result, campaignId, observations, status } = leadData;

        let date = new Date(createdAt);
        date = date.toLocaleDateString() + " " + date.toLocaleTimeString();

        let campaign = CompanyConfig.LeadsCampaigns.find(x => x.id == campaignId + "");
        let sourceLabel = CompanyConfig.LeadsSources.find(x => x.id == campaign.sourceId + "")?.name;
        const resultIcon = result === "success" ? (
          <span className={styles.successIcon}><CheckOutlined htmlColor={ColorPalette.green}/></span>
        ) : result === "failure" ? (
          <span className={styles.failureIcon}><CloseRounded htmlColor={ColorPalette.red}/></span>
        ) : null;

        const leadElement = (
          <div key={i} className={styles.leadCard}>
        <div className={styles.leadHeader}>
          <div className={styles.leadDate}>{date}</div>
          <div className={styles.leadName}>{name}</div>
        </div>
        <div className={styles.leadSecondaryInfo}>
          <div>{phone}</div>
        </div>
        <div className={styles.leadTags}>
          <span className={styles.leadTag}>{channel}</span>
          <span className={styles.leadTag}>{sourceLabel}</span>
          <span className={styles.leadTag}>{campaign.name}</span>
        </div>
        <div className={styles.leadActions} style={{ display: "flex", gap: '10px' }}>
          <IconWhatsapp style={{ cursor: "pointer" }} fontSize='small' onClick={() => onWhatssappMessageClickHandler(leadData)} />
          <IconEdit style={{ cursor: "pointer" }} fontSize='small' onClick={() => showCreateEditLeadPopup(leadData)} />
          {resultIcon}
        </div>
          </div>
        );

        if (status == "new") {
          columns.new.push(leadElement);
        } else if (status == "in_progress") {
          columns.inProgress.push(leadElement);
        } else if (status == "finished") {
          columns.finished.push(leadElement);
        }
      });

      return (
        <div className={styles.containerColumns}>
          <div className={styles.column}>
            <h3>Novo</h3>
            {columns.new}
          </div>
          <div className={styles.column}>
            <h3>Em andamento</h3>
            {columns.inProgress}
          </div>
          <div className={styles.column}>
            <h3>Finalizado</h3>
            {columns.finished}
          </div>
        </div>
      );
    }

  const getList = () => {
    const list = [];
    let start = currentPage * maxItemsPerPage;
    let end = Math.min(start + maxItemsPerPage, filtered.length);

    for (let i = start; i < end; i++) {
      let leadData = filtered[i];
      let { createdAt, name, phone, channel, result, campaignId, observations } = leadData;

      let date = new Date(createdAt);
      date = date.toLocaleDateString() + " " + date.toLocaleTimeString();

      let campaign = CompanyConfig.LeadsCampaigns.find(x => x.id == campaignId + "");
      let sourceLabel = CompanyConfig.LeadsSources.find(x => x.id == campaign.sourceId + "")?.name;

      list.push(
        <tr key={i}>
          <td>{date}</td>
          <td>{name}</td>
          <td>{phone}</td>
          <td>{channel}</td>
          <td>{sourceLabel}</td>
          <td>{campaign.name}</td>
          <td>{result}</td>
          <td>{observations}</td>
          <td>
            <div style={{ display: "flex", gap: '10px' }}>
              <IconWhatsapp style={{ cursor: "pointer" }} fontSize='small' onClick={() => onWhatssappMessageClickHandler(leadData)} />
              <IconEdit style={{ cursor: "pointer" }} fontSize='small' onClick={() => showCreateEditLeadPopup(leadData)} />
            </div>
          </td>
        </tr>
      );
    }

    return (
      <div className={styles.containerTable}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Data</th>
              <th>Nome</th>
              <th>Telefone</th>
              <th>Canal</th>
              <th>Origem</th>
              <th>Campanha</th>
              <th>Status</th>
              <th>Obs</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {list}
          </tbody>
        </table>
      </div>
    );
  }

  function onWhatssappMessageClickHandler(leadData) {
    let message = "";
    SendWhatsAppMessage(leadData.phone, MessagesTemplate.ConvertToWhatsAppText(message));

  }

  function showCreateEditLeadPopup(leadData) {
    setCurrentLead(leadData);
    setCreateOrEditPopupVisibility(true);
  }

  function onCreateEditLeadPopupHide() {
    setCreateOrEditPopupVisibility(false);
  }

  function onLeadUpdate() {
    setNeedsReload(true);
  }

  function onCreateLeadClickHandler() {
    setCurrentLead(null);
    setCreateOrEditPopupVisibility(true);

  }

  function onExportExcelClickHandler() {
    let data = [...filtered];

    data = data.map(({ id, companyId, phone, name, sourceId, campaignId, updatedAt, updatedBy
      , ...rest }) => {
      let campaign = CompanyConfig.LeadsCampaigns.find(x => x.id == campaignId + "");
      let campaignName = campaign.name;
      let source = CompanyConfig.LeadsSources.find(x => x.id == campaign.sourceId + "")?.name;
      return { ...rest, source, campaignName };
    });

    ExportToExcel(data, "Leads", "leads-" + startDate + "-" + endDate);
  }

  const handleDateChange = (initialDate, endDate) => {
    setStartDate(initialDate);
    setEndDate(endDate);
    //loadData(initialDate, endDate);
  }


  function formatDate(date) {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }

  return (
    <div className='page-content'>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "20px" }}>
          <AreaTitle Title='Leads' Description={"Contatos que mostraram interesse nos serviços da clínica."} />
          {<CustomButton
            variant="contained"
            style="primary"
            label={"Adicionar"}
            onClick={onCreateLeadClickHandler}
          />}
        </div>

        <DateRangeHeader onChange={handleDateChange} />
        {/*<FilterList ref={filterRef} id="reportsFinancial" label="Filtros" filters={filters} onChange={filterChangeHandler} initOpened={true} />*/}

        <div style={{ display: "flex", flex: "1", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <AreaTitle Title="Resultado" Description={`<b>${filtered.length} leads</b> encontrados no período de <b>${formatDate(startDate)} à ${formatDate(endDate)}</b>`} />
          <CustomButton
            variant="contained"
            style="primary"
            label={"Exportar para Excel"}
            onClick={onExportExcelClickHandler}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <p>Modo de exibição</p>
          <Select
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'View Mode' }}
          >
            <MenuItem value={VIEW_MODE_LIST}>Lista</MenuItem>
            <MenuItem value={VIEW_MODE_COLUMN}>Coluna</MenuItem>
          </Select>
        </div>

        {viewMode === VIEW_MODE_LIST && getList()}
        {viewMode === VIEW_MODE_COLUMN && getColumn()}

        {createOrEditPopupVisibility && <CreateEditLeadPopup OnHide={onCreateEditLeadPopupHide} OnUpdate={onLeadUpdate} Data={currentLead} />}
      </div>
    </div>
  );
}

export default Leads;